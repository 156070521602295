import { render, staticRenderFns } from "./chat-history-messages-viewer.vue?vue&type=template&id=d6255b7c&scoped=true&"
import script from "./chat-history-messages-viewer.vue?vue&type=script&lang=ts&"
export * from "./chat-history-messages-viewer.vue?vue&type=script&lang=ts&"
import style0 from "./chat-history-messages-viewer.vue?vue&type=style&index=0&id=d6255b7c&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d6255b7c",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
installComponents(component, {VBtn,VCard,VCardText,VCol,VIcon,VRow})
